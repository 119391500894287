<template>
  <div class="main">
    <div class="login-bj">
      <div class="box">
        <div class="box-right">
          <div class="login-box">

            <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm" label-width="0"
                     label-position="right" class="demo-loginForm">
              <div class="title">
                <p>电子教材阅读系统</p>
                <p>登 录</p>
              </div>

              <el-form-item prop="checkName">
                <el-input type="text" v-model="loginForm.checkName" autocomplete="off" placeholder="请输入手机号或邮箱号"></el-input>
              </el-form-item>

              <el-form-item prop="checkPass">
                <el-input type="password" v-model="loginForm.checkPass" autocomplete="off"
                          placeholder="请输入登陆密码"></el-input>
              </el-form-item>
              <el-form-item>
                <div style="width: 100%;display: flex;flex-direction: row;justify-content: space-between">
                  <el-checkbox v-model="loginForm.delivery">记住密码</el-checkbox>
                  <el-link :underline="false" href="#/register">用户注册</el-link>
                </div>
              </el-form-item>
              <el-form-item style="text-align: center;margin-bottom: 10px">
                <el-button type="primary" style="width: 300px" @click="submitForm('loginForm')">登 录</el-button>

              </el-form-item>
              <el-form-item>
                <!--                <p style="text-align: center;">登录即视为同意<span @click="openAgreement=true"-->
                <!--                                                            style="color: #409EFF;cursor: pointer">《服务协议》</span>及<span-->
                <!--                    @click="openPrivacy=true" style="color: #409EFF;cursor: pointer">《隐私政策》</span>-->
                <!--                </p>-->
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="footer">
        <p>Copyright © {{ new Date().getFullYear() }} All Rights Reserved</p>
      </div>
    </div>
  </div>
</template>

<script>
import Agreement from "../protocols/agreement";
import Privacy from "../protocols/privacy";
import JSEncrypt from "jsencrypt";
import store from "../../store";
import * as types from "../../store/type";


export default {
  name: "Login",
  components: {Privacy, Agreement},
  data() {

    let validatePass = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    let validateName = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请输入手机号或者邮箱'));
      } else {
        callback();
      }
    };
    return {
      openAgreement: false,
      openPrivacy: false,
      loginForm: {
        checkPass: window.localStorage.getItem('checkPass'),
        checkName: window.localStorage.getItem('checkName'),
        delivery: true,
      },
      rules: {
        checkPass: [
          {validator: validatePass, trigger: 'blur'}
        ],
        checkName: [
          {validator: validateName, trigger: 'blur'}
        ],
      }
    };
  },
  methods: {
    submitForm(formName) {
      let _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          let encrypt = new JSEncrypt();
          encrypt.setPublicKey(_this.global.PUBLICKEY);
          let params = {
            checkPass: encodeURI(encrypt.encrypt(_this.loginForm.checkPass)).replace(/\+/g, '%2B'),
            checkName: encodeURI(encrypt.encrypt(_this.loginForm.checkName)).replace(/\+/g, '%2B'),
            delivery: _this.loginForm.delivery,
          };
          _this.axios.post('/login', params)
              .then(function (res) {
                if (res.code == 200) {
                  window.localStorage.setItem('access_token', res.token);
                  if (_this.loginForm.delivery) {
                    window.localStorage.setItem('checkName', _this.loginForm.checkName);
                    window.localStorage.setItem('checkPass', _this.loginForm.checkPass);
                  } else {
                    window.localStorage.setItem('checkName', '');
                    window.localStorage.setItem('checkPass', '');
                  }
                  store.commit(types.LOGIN, window.localStorage.getItem('access_token'))
                  let redirect = decodeURIComponent('/');
                  _this.$router.push({
                    path: redirect
                  })
                } else {
                  _this.$message.error(res.msg);
                }
              })
              .catch(function (error) {
                console.log(error);
              });

        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
.main {
  position: fixed;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  background-image: url("../../assets/images/login-background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.login-bj {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  font-size: 0;

  .box {
    width: 450px;
    height: 480px;
    border-radius: 10px;
    background: #ffffff;
    display: flex;
    lex-direction: row;
    border: 1px solid #f2f2f2;

    .box-right {
      width: 100%;
      height: 600px;

      .demo-loginForm {
        width: 80%;
        box-sizing: border-box;
        margin: 10px auto;

        //border: 1px solid #0a73bb;
        border-radius: 10px;
        //box-shadow: 0px 0px 5px #888888;

        .title {
          width: 100%;
          margin-top: 50px;
          height: 100px;
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 20px;
        }
      }
    }

  }

  .footer {
    height: 80px;
    width: 100%;
    bottom: 0;
    text-align: center;
    font-size: 14px;
    color: #ffffff;

    a {
      color: #000000;
    }
  }
}
</style>