<template>
  <div class="header-bottom">

    <div class="header-bottom-center">
      <div class="title">电子教材阅读系统</div>
      <el-menu :default-active="$route.path" router class="el-menu-demo" mode="horizontal">
        <el-menu-item class="website-nav" v-for="(item,index) in navList" :index="item.url" :key="index">
          {{ item.name }}
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      activeIndex: '1',
      navList: [
        {id: 1, name: '首页', url: '/'},
        {id: 5, name: '电子教材库', url: '/book/library'},
        {id: 2, name: '推荐书籍', url: '/book/recommend'},
        {id: 3, name: '热门书籍', url: '/book/hot'},
        {id: 4, name: '阅读榜', url: '/book/ranking'},
        {id: 6, name: '我的收藏', url: '/my/collection'},
        {id: 7, name: '我的书架', url: '/my/bookshelf'},
        {id: 7, name: '检索', url: '/book/search'},
      ]
    }
  },
  created() {
  },
  computed: {
    // activeIndex() {
    //   // if (this.$route) {
    //   // }
    // }
  }
}
</script>

<style lang="less" scoped>

.header-bottom {
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #EBEEF5;

}

.header-bottom-center {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 20px;
    font-weight: bold;
  }

}

.website-nav {
  width: 90px;
  padding: 0 !important;
  text-align: center;
  color: #333 !important;
}

.el-menu.el-menu--horizontal {
  border: initial;
}
</style>