import Vue from 'vue'
import 'jsencrypt'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './http'


Vue.prototype.axios = axios;

//引入element组件
import './plugins/element.js'

//引入css-less组件
import less from 'less'

Vue.use(less);

import echarts from 'echarts'

Vue.prototype.$echarts = echarts

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

//引入阿里适量图标
import '../src/assets/aliIconfont/iconfont.css'

//引入阿里适量图标
import '../src/assets/styles/icon.css'

//引入全局常量
import global from './const.js'

import * as types from "./store/type";

Vue.prototype.global = global;


Vue.config.productionTip = false

// 获取菜单
Vue.prototype.GetNavMenu = () => {
    // if (window.sessionStorage.getItem('CseIeFxCzkArFB2w')) {
    //     store.commit(types.GET_NAV_MENU, JSON.parse(window.sessionStorage.getItem('CseIeFxCzkArFB2w')))
    // }
    axios.get('/common/get_nav_menu').then((res) => {
        window.sessionStorage.setItem('CseIeFxCzkArFB2w', JSON.stringify(res));
        store.commit(types.GET_NAV_MENU, JSON.parse(window.sessionStorage.getItem('CseIeFxCzkArFB2w')))
    })
}
// 获取地域列表
Vue.prototype.GetAreaList = (success) => {
    // if (window.localStorage.getItem('NF8LJEgingsijAjg')) {
    //     "function" == typeof success && success(JSON.parse(window.localStorage.getItem('NF8LJEgingsijAjg')));
    //     return false;
    // }
    axios.get('/common/get_area').then((res) => {
        window.localStorage.setItem('NF8LJEgingsijAjg', JSON.stringify(res));
        "function" == typeof success && success(JSON.parse(window.localStorage.getItem('NF8LJEgingsijAjg')));
    })
}

// 获取学校列表
Vue.prototype.GetGradeList = (success) => {
    // if (window.localStorage.getItem('eFMOX0aGouUdnKfq')) {
    //     "function" == typeof success && success(JSON.parse(window.localStorage.getItem('eFMOX0aGouUdnKfq')));
    //     return false;
    // }
    axios.get('/common/get_user_grade').then((res) => {
        window.localStorage.setItem('eFMOX0aGouUdnKfq', JSON.stringify(res));
        "function" == typeof success && success(JSON.parse(window.localStorage.getItem('eFMOX0aGouUdnKfq')));
    })
}
// 获取组织部门
Vue.prototype.getOrg = (success) => {
    // if (window.sessionStorage.getItem('wjzhDDgZFlZVUAGP')) {
    //     "function" == typeof success && success(JSON.parse(window.sessionStorage.getItem('wjzhDDgZFlZVUAGP')));
    //     return false;
    // }
    axios.get('/common/get_org').then((res) => {
        window.sessionStorage.setItem('wjzhDDgZFlZVUAGP', JSON.stringify(res));
        "function" == typeof success && success(JSON.parse(window.sessionStorage.getItem('wjzhDDgZFlZVUAGP')));
    })
}


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
