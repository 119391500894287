<template>
  <div style="margin: 40px auto;width: 1200px">
    <div style="width: 100%;display: flex;flex-direction: row">
      <div style="width: 800px">
        <el-carousel :interval="4000" type="card" height="200px">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <el-image :src="global.apiUri+item.img" @click="openBookInfo(item.book_id)"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div style="width: 400px;padding-left: 100px;box-sizing: border-box;display: flex;flex-direction: column">
        <h1>公告</h1>
        <div v-for="(item,index) in noticeList" :key="index" style="height: 30px;">
          <el-link :underline="false" @click="openNotice(item.id)">
            <div>{{ (index + 1) + ". " + item.title }}
              <el-badge value="热" v-show="index<3">
                <i class="el-icon-trophy" style="color: red;"></i>
              </el-badge>
            </div>
          </el-link>
        </div>
      </div>
    </div>
    <el-divider></el-divider>

    <h1><i class="el-icon-s-opportunity" style="color:rgb(48, 176, 143)"></i>推荐书籍</h1>
    <div class="tuijian-box">
      <div class="tuijian" v-for="(o, index) in recommendList" :key="index" @click="openBookInfo(o.bookId)">
        <el-image
            style="width: 150px;height:210px;border-radius: 4px"
            fit="cover"
            :src="global.apiUri+o.cover"></el-image>
        <div class="book-info-box">
          <div class="book-title">{{ o.title }}</div>
          <div class="author">{{ o.author }} 著</div>
        </div>
      </div>
    </div>
    <el-divider></el-divider>

    <div class="shuku-box">
      <div class="shuku-left">
        <div class="top">
          <h1><i class="el-icon-s-management" style="color:rgb(48,176,143)"></i>书库</h1>
          <div class="shuku-more">
            <router-link to="/book/library">
              <el-link style="margin-top: 10px">
                更多>>
              </el-link>
            </router-link>
          </div>
        </div>
        <div class="bottom" style="min-height: 500px">
          <div class="shuku-left-box-line" v-for="(o, index) in booksList" :key="index">
            <div class="shuku-left-box" @click="openBookInfo(o.bookId)">
              <el-image
                  style="width: 150px;height:210px;border-radius: 4px"
                  fit="cover"
                  :src="global.apiUri+o.cover"></el-image>
              <div class="right">
                <div style="width: 200px">
                  <div class="book-title">{{ o.title }}</div>
                  <p>{{ o.author }} 著</p>
                  <p>分类：{{ o.cate }}</p>
                  <p>发布时间：{{ o.date }}</p>
                </div>
                <el-button type="text">立即阅读</el-button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="shuku-right">
        <h1>书库阅读总榜</h1>
        <div style="height: 30px;display: flex;flex-direction: column">
          <div v-for="(item,index) in readTopList" :key="index">
            <div v-if="index<=2" style="margin-bottom: 10px" @click="openBookInfo(item.bookId)">
              <el-badge :value="'NO.'+ (index+1)">
                <el-card :body-style="{ padding: '0px'}" shadow="hover">
                  <div style="display: flex;flex-direction: row">
                    <el-image
                        style="width: 120px;height:160px;border-radius: 4px"
                        fit="cover"
                        :src="global.apiUri+item.cover"></el-image>
                    <div style="width: 200px;padding:10px;box-sizing: border-box">
                      <div class="book-title">{{ item.title }}</div>
                      <p>{{ item.author }} 著</p>
                      <p>分类：{{ item.cate }}</p>
                      <el-link>立即阅读 ></el-link>
                    </div>
                  </div>
                </el-card>
              </el-badge>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div style="display: none">
    <el-divider></el-divider>
    <h1><i class="el-icon-star-on" style="color:rgb(48,176,143)"></i> 猜你喜欢
      <el-button type="text" icon="el-icon-refresh">换一批</el-button>
    </h1>
    <div class="cai-ni">
      <div class="cai-ni-box" v-for="(o, index) in userLikeList" :key="index">
        <div class="book-info" @click="openBookInfo(o.bookId)">
          <el-image
              style="width: 150px;height:210px;border-radius: 4px"
              fit="cover"
              :src="global.apiUri+o.cover"></el-image>
          <div class="right">
            <div style="width: 200px">
              <div class="book-title">{{ o.title }}</div>
              <p>{{ o.author }} 著</p>
              <p>分类：{{ o.cate }}</p>
              <p> 发布时间：{{ o.date }}</p>
            </div>
            <el-button type="text">立即阅读</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>
<script>


export default {
  name: 'Home',
  components: {},
  data() {
    return {
      lunbo: [
        {bookId: 1, bookCover: 'http://39.96.5.132/upload_files/ebook_cover/201608131653003491.png'},
        {bookId: 1, bookCover: 'http://39.96.5.132/upload_files/ebook_cover/201608131653003491.png'},
        {bookId: 1, bookCover: 'http://39.96.5.132/upload_files/ebook_cover/201608131653003491.png'},
        {bookId: 1, bookCover: 'http://39.96.5.132/upload_files/ebook_cover/201608131653003491.png'},
      ],
      noticeList: [],
      userLikeList: [],
      readTopList: [],
      bannerList: [],
      booksList: [],
      recommendList: [],
    }
  },
  created() {
    this.getBanner();
    this.getRecommend();
    this.getBookList();
    this.getReadTop();
    this.getNotice();
    // this.getUserLike();
  },
  methods: {
    openNotice(noticeId){
      this.$router.push({path: '/book/noticeInfo', query: {id: noticeId}});
    },
    getBanner() {
      let _this = this;
      let url = '/ebook/get-banner';
      _this.axios.get(url).then(function (res) {
        if (res.code == 200) {
          _this.bannerList = res.data;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    getRecommend() {
      let _this = this;
      let url = '/ebook/recommend' + '?page=1&limit=6';
      _this.axios.get(url).then(function (res) {
        if (res.code == 200) {
          _this.recommendList = res.data;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    getBookList() {
      let _this = this;
      let url = '/ebook/book_list' + '?page=1&limit=6';
      _this.axios.get(url).then(function (res) {
        if (res.code == 200) {
          _this.booksList = res.data;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    getNotice() {
      let _this = this;
      let url = '/ebook/get_notice' + '?page=1&limit=6';
      _this.axios.get(url).then(function (res) {
        if (res.code == 200) {
          _this.noticeList = res.data;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    getReadTop() {
      let _this = this;
      let url = '/ebook/read_top' + '?page=1&limit=6';
      _this.axios.get(url).then(function (res) {
        if (res.code == 200) {
          _this.readTopList = res.data;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    getUserLike() {
      let _this = this;
      let url = '/ebook/user_like' + '?page=1&limit=6';
      _this.axios.get(url).then(function (res) {
        if (res.code == 200) {
          _this.userLikeList = res.data;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    openBookInfo(bookId) {
      this.$router.push({path: '/book/bookInfo', query: {bookId: bookId}});
    }
  },
}
</script>
<style scoped lang="less">
.home {
  width: 100%;
}

h1 {
  font-size: 18px;
}

.book-title {
  flex: 1;
  font-size: 16px !important;
  font-weight: bold !important;
  margin-bottom: 5px !important;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //用省略号显示
  white-space: nowrap; //不换行
}

.tuijian-box {
  width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  .tuijian {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .book-info-box {
      width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      font-size: 14px;
      padding-left: 10px;
      margin-top: 10px;


    }
  }
}

.shuku-box {
  display: flex;
  flex-direction: row;

  .shuku-left {
    width: 800px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 20px;
      box-sizing: border-box;
    }

    .bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .shuku-left-box-line {
      width: 400px;
      padding-left: 5px;
      padding-right: 5px;
      box-sizing: border-box;

    }

    .shuku-left-box {
      width: 380px;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      height: 210px;
      border-radius: 5px;
      border: 1px solid #E3DEDE74;
      box-sizing: border-box;
      cursor: pointer;

      .right {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 14px;

      }
    }

  }

  .shuku-right {
    width: 400px;
  }

}

.cai-ni {
  width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .cai-ni-box {
    width: 400px;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;

    .book-info {
      width: 380px;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      height: 210px;
      border-radius: 5px;
      border: 1px solid #E3DEDE74;
      box-sizing: border-box;
      font-size: 14px;
      cursor: pointer;

      .right {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

      }
    }
  }
}
</style>
