import Vue from 'vue';
import store from '../store/index';
import * as types from '../store/type';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/login/Login.vue';


export default [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requireAuth: true,
            title: '电子教材制作与管理系统-首页',
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,

    },
    {
        path: '/register',
        name: 'register',
        component: (resolve) => require(['@/views/login/register'], resolve)
    },
    {
        path: '/book/recommend',
        name: 'recommend',
        component: (resolve) => require(['@/views/book/recommend'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/book/hot',
        name: 'hot',
        component: (resolve) => require(['@/views/book/hot'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/book/ranking',
        name: 'ranking',
        component: (resolve) => require(['@/views/book/ranking'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/book/library',
        name: 'library',
        component: (resolve) => require(['@/views/book/library'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/book/search',
        name: 'library',
        component: (resolve) => require(['@/views/book/search'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/my/collection',
        name: 'collection',
        component: (resolve) => require(['@/views/my/collection'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/my/bookshelf',
        name: 'bookshelf',
        component: (resolve) => require(['@/views/my/bookshelf'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/my/browse',
        name: 'browse',
        component: (resolve) => require(['@/views/my/browse'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/my/info',
        name: 'browse',
        component: (resolve) => require(['@/views/my/info'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/book/bookInfo',
        name: 'bookInfo',
        component: (resolve) => require(['@/views/book/bookInfo'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/book/noticeInfo',
        name: 'reader',
        component: (resolve) => require(['@/views/book/noticeInfo'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/book/reader',
        name: 'reader',
        component: (resolve) => require(['@/views/book/reader'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/book/reader_txt',
        name: 'reader',
        component: (resolve) => require(['@/views/book/reader_txt'], resolve),
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/book/reader_pdf',
        name: 'reader',
        component: (resolve) => require(['@/views/book/reader_pdf'], resolve),
        meta: {
            requireAuth: true,
        }
    },
];

