import Vue from 'vue'
import Vuex from 'vuex'
import * as types from './type'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {},
        access_token: null,
        title: '',
        navMenu: '',
        navMenuModules: ''
    },
    mutations: {
        [types.LOGIN]: (state, data) => {
            localStorage.access_token = data;
            state.access_token = data;
        },
        [types.LOGOUT]: (state) => {
            localStorage.removeItem('access_token');
            state.access_token = null
        },
        [types.TITLE]: (state, data) => {
            state.title = data;
        },
        [types.GET_NAV_MENU]: (state, data) => {
            state.navMenu = data;
        },
        [types.GET_NAV_MENU_MODULES]: (state, data) => {
            state.navMenuModules = data;
        }
    },
    actions: {},
    modules: {}
})
