<template>
  <div class="website-header">
    <div class="header-top">
      <div class="header-top-info">
        <div class="left">
          <div style="font-size: 12px;">欢迎来到电子教材阅读系统</div>
<!--          <el-link style="color: #ffff;font-size: 12px;margin-left: 20px" :underline="false" type="primary"-->
<!--                   href="mailto:service@xibeicity.com">服务邮箱：service@test.com-->
<!--          </el-link>-->
        </div>
        <div class="right" v-if="userToken">
          <el-link style="color: #ffffff;margin-right: 10px" :underline="false" href="#/my/info">{{ userInfo. username}}</el-link>
          |
          <el-link style="color: #ffffff;margin-left: 10px" :underline="false" @click="logout">退出登录</el-link>
        </div>
        <div class="right" v-else>
          <el-link style="color: #ffffff;margin-right: 10px" :underline="false" href="#/login">登录</el-link>
          |
          <el-link style="color: #ffffff;margin-left: 10px" :underline="false" href="#/register">注册</el-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store";
import * as types from "../store/type";
import {LOGOUT} from "../store/type";

export default {
  name: "HeaderTop",
  data() {
    return {
      loginShow: false,
      userInfo: {},
      userToken: window.localStorage.getItem('access_token')
    }
  },
  watch: {
    '$store.state.access_token': {
      handler: function (newVal, oldVal) {
        this.userToken = window.localStorage.getItem('access_token');
        this.getUserInfo();
      },
      deep: true
    }
  },
  created() {
    let _this = this;
    this.getUserInfo();
  },
  methods: {
    login() {
      this.$router.push({
        path: '/login'
      })
    },
    getUserInfo() {
      let _this = this;
      _this.axios.get('/user/get-info')
          .then(function (res) {
            if (res.code == 200) {
              _this.userInfo = res.data;

            }
          })
    },
    logout() {
      let _this = this;
      _this.axios.get('/login/logout')
          .then(function (res) {
            if (res.code == 200) {
              _this.userToken = null;
              window.localStorage.removeItem('access_token');
              store.commit(types.LOGOUT);
              _this.$router.push({
                path: '/login'
              })
            }
          })
    }
  }
}
</script>

<style lang="less" scoped>


.website-header {
  width: 100%;
  box-sizing: border-box;

  .header-top {
    width: 100%;
    height: 30px;
    color: #ffffff;
    font-size: 12px;
    background: #0f0f0f;
    line-height: 30px;

    .header-top-info {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left, .right {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .right {
        justify-content: flex-end;
        width: 300px;
        text-align: right;
      }
    }

  }
}


</style>