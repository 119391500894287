import Vue from 'vue'
import store from '../store/index'
import * as types from '../store/type'
import VueRouter from 'vue-router'
import routes from './routers';

Vue.use(VueRouter)


// 页面刷新时，重新赋值token
if (window.localStorage.getItem('access_token')) {
    store.commit(types.LOGIN, window.localStorage.getItem('access_token'))
}
const router = new VueRouter({
    routes,
    base: '/',
    mode: 'hash' //history || hash
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    if (to.meta.title) {
        document.title = to.meta.title
    } else {
        document.title = '电子出阅读系统'
    }
    if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
        if (store.state.access_token) {  // 通过vuex state获取当前的token是否存在
            next();
        } else {
            next('/login')
        }
    } else {
        next();
    }
})
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//     if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
//     return originalPush.call(this, location).catch((err) => err)
// }
export default router
