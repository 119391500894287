<template>
  <div>
    <h1 class="bold">xibeicity隐私政策</h1>
    <summary class="bold time">更新时间：<span class="line">2020年10月24日</span></summary>
    <summary class="bold time">生效时间：<span class="line">2020年10月31日</span></summary>

    <p class="bold">提示条款：</p>

    <p>
      我们深知个人信息对您的重要性，我们将按中国法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。据此，xibeicity服务的提供者（或简称“我们”）制定本《隐私政策》（下称“本政策”）并提醒您：
    </p>
    <p>
      <span class="bold">本政策仅适用于本平台（xibeicity客户端或网页（域名：www.xibeicity.com））自身的功能、产品及服务。</span>如我们关联公司的产品或服务中使用了xibeicity提供的产品或服务但未设独立的隐私政策的，则本政策同样适用于该部分产品或服务。
    </p>

    <p class="bold line">
      需要特别说明的是，本隐私政策不适用于第三方通过本平台直接向您提供的服务（统称“第三方服务”），如您通过xibeicity提供的第三方应用功能入口，开通使用的第三方应用服务（扫名片、扫码收款、呼叫中心功能、直播、AI图片智能识别、电子签章、视频会议等）；如您通过xibeicity客户端绑定支付公司提供的支付、转账等支付相关功能。您向该第三方服务提供者提供的信息不适用于本政策，您在选择使用第三服务前应充分了解第三方服务的产品功能及隐私保护政策，再选择是否开通功能。
    </p>
    <p class="bold line">
      在使用xibeicity产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用xibeicity产品或服务，即表示您已充分理解并同意本政策。
    </p>

    <article>
      <section>
        <p class="bold line">第一部分 定义</p>
        <ul>
          <li>
            1. <span class="bold">xibeicity服务提供者：</span>是指提供xibeicity产品和服务的法律主体，为<span
              class="bold">北京易动纷享科技有限责任公司</span>，以下简称<span class="bold">“xibeicity”</span>或者<span class="bold">“我们”</span>。
          </li>
          <li>
            2. <span class="bold">支付公司：</span>
            指提供支付功能服务的主体：支付宝（中国）网络技术有限公司、财付通支付科技有限公司及快钱支付清算信息有限公司。
          </li>
          <li>
            3. <span class="bold">企业/组织用户：</span>指通过企业或其他组织授权申请注册成为xibeicity用户，并通过管理员用户在xibeicity平台上传企业/组织员工通讯录搭建企业/组织架构后，由管理员用户代表企业/组织开通、管理和使用xibeicity服务。xibeicity企业/组织用户包括但不限于法人、政府机构、其他组织、合伙或个体工商户等。
          </li>
          <li>
            4. <span class="bold">企业/组织管理员：</span>
            指经企业/组织用户授权或指定，拥有企业/组织用户管理平台系统操作权限，代表企业/组织开通、管理和使用xibeicity服务的xibeicity个人用户。
          </li>
          <li>
            5. <span class="bold">个人用户：</span>
            是指企业/组织用户管理员权限的个人用户、作为企业/组织用户通讯录一员但不具有企业/组织管理员权限的个人用户，以及企业/组织用户管理员创建的外部用户，以下称<span class="bold">“您”</span>。
          </li>
          <li>
            6. <span class="bold">个人信息：</span>指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
          </li>
          <li>
            7.<span class="bold"> 个人敏感信息：</span>是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。xibeicity服务的个人敏感信息包括身份证件号码、手机号码、个人生物识别信息、银行账号、财产信息、交易信息、14岁以下（含）儿童的个人信息等（我们将在本隐私政策中对具体个人敏感信息以<span
              class="bold">粗体</span>进行显著标识）。
          </li>
          <li>
            8.<span class="bold"> 儿童：</span>是指不满十四周岁的未成年人。
          </li>
          <li>
            9.<span class="bold"> 个人信息删除：</span>
            指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
          </li>
          <li>
            10.<span class="bold"> 个人信息匿名化：</span>
            通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。
          </li>
          <li>
            11. <span class="bold">个人信息控制者：</span>有权决定个人信息处理目的、方式等的组织或个人。本协议xibeicity服务中的部分个人信息控制者为<span
              class="bold">企业/组织用户</span>。
          </li>
        </ul>
        <p>
          除另有约定外，本政策所用定义与《xibeicity服务协议》中的定义具有相同的涵义。
        </p>
      </section>

      <section>
        <p class="bold">
          第二部分 隐私权政策
        </p>
        <p>
          下述部分将帮助您了解以下内容：
        </p>
        <ul>
          <li>
            一、我们如何收集和使用您的个人信息
          </li>
          <li>
            二、我们如何使用Cookie和同类技术
          </li>
          <li>
            三、我们如何共享、转让、公开披露您的信息
          </li>
          <li>
            四、我们如何保护您的信息
          </li>
          <li>
            五、您如何管理您的信息
          </li>
          <li>
            六、我们如何处理儿童的信息
          </li>
          <li>
            七、本隐私权政策如何更新
          </li>
          <li>
            八、如何联系我们
          </li>
        </ul>

        <section>
          <p class="bold">一、我们如何收集和使用您的个人信息</p>
          <p>在您使用我们的xibeicity产品及/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：</p>
          <p class="bold line">1、为实现向您提供我们xibeicity产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；</p>
          <p class="bold line">
            2、为实现向您提供我们产品及/或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能</p>
          <p>您理解并同意：</p>
          <p>
            <span class="bold line">为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。</span>在此过程中，如果您有任何疑问、意见或建议的，您可通过xibeicity提供的各种联系方式与我们联系，我们会尽快为您作出解答。
          </p>
          <p>
            为了向您及企业/组织用户提供xibeicity服务，维护xibeicity服务的正常运行，改进及优化我们的服务体验并保障您的账号安全，我们会出于本政策下述目的及方式收集您在注册、使用xibeicity服务时主动提供、授权提供或基于您所在企业/组织用户要求提供的信息，以及基于您使用xibeicity服务时产生的信息：
          </p>
          <section>
            <p class="bold">
              （一）帮助您成为xibeicity注册用户
            </p>
            <li>
              <p class="bold">
                1.1 注册成为xibeicity企业/组织用户信息
              </p>
              <p>
                为注册成为企业/组织用户，以便我们基于您所在企业/组织的雇佣及管理行为提供xibeicity服务，诸如xibeicity人事应用服务（员工考勤、请假管理）、xibeicity智能办公应用服务（包括基于企业/组织管理审批、日志、日程、智能文档协同编辑、绑定企业邮箱）、企业群组聊天等，以实现企业/组织的数字化办公转型升级，您需要提供您的企业/组织名称、营业执照复印件及联系方式用以创建xibeicity企业/组织账户，否则您将不能基于企业/组织管理行为使用xibeicity服务。注册成为xibeicity企业/组织用户后，您可进一步提供企业/组织所在地、行业类型、人员规模信息。如果您需要获得更多权益（诸如订货通等功能开通等），企业/组织管理员用户需代表企业/组织提交特定信息和资料以完成xibeicity企业/组织认证，包括营业执照复印件、企业/组织名称、经营地址、<span
                  class="bold">法人代表姓名、法人代表身份证号</span>等基本信息。
              </p>
            </li>
            <li>
              <p class="bold">
                1.2 注册成为xibeicity个人用户信息
              </p>
              <p>
                xibeicity暂不支持个人用户开放注册，需通过企业/组织用户管理员创建，为创建您成为个人用户，您所属的企业/组织用户管理员需提供您的
                <span class="bold line">手机号码及姓名</span>
                ，以便我们为您提供诸如IM聊天（即时通讯）、智能办公应用等服务，否则您将不能使用xibeicity服务。
                <span class="bold line">注册成为xibeicity个人用户后，您可进一步提供头像、昵称、性别、职位、汇报对象及企业邮箱等基本信息。</span>
              </p>
            </li>

          </section>
          <section>
            <p class="bold">（二）使用xibeicity服务过程中收集信息</p>
            <p>
              当您在使用xibeicity服务过程中，为向您提供更契合您需求的xibeicity服务、交互展示、搜索结果、识别账号异常状态，维护xibeicity服务的正常运行，改进及优化您对xibeicity服务的体验并保障您的账号安全，需要收集您使用xibeicity服务以及使用方式的信息，并将这些信息进行关联：
            </p>
            <ul>
              <li>
                <p class="bold">2.1 使用信息</p>
                <p>
                  我们会收集您作为xibeicity个人用户使用xibeicity服务时提交或产生的信息。如您使用xibeicity进行IM聊天，我们需要记录您的
                  <span class="bold line">聊天信息</span>
                  以便实现信息的传输与交互。
                </p>
              </li>
              <li>
                <p class="bold">
                  2.2 设备信息
                </p>
                <p>
                  我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的<span class="bold line">设备相关信息（例如设备型号、操作系统版本、设备设置等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS/北斗位置信息以及能够提供相关信息的Wi-Fi接入点、和基站等传感器信息）</span>。
                </p>
              </li>
              <li>
                <p class="bold">
                  2.3 日志信息
                </p>
                <p>
                  当您使用我们的客户端、网站提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间、您访问的网页记录、审批日志信息、考勤日志信息、外勤日志信息、CRM对象操作日志信息等操作日志信息。
                </p>
                <p>
                  <span class="bold line">请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。</span>
                  如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将有可能被视为个人信息，除取得您授权（企业/组织用户依据本政策享有的数据控制权视为您授权）或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
                </p>
              </li>
              <li>
                <p class="bold">
                  2.4 您通过我们的客服或参加我们举办的活动时所提交的信息
                </p>
                <p>
                  例如，您参与我们举办的活动（线上或线下）时填写的调查问卷中可能包含您的姓名、电话、家庭地址等信息。
                </p>
              </li>
              <li>
                <p class="bold">
                  2.5 企业/组织控制数据
                </p>
                <p>
                  如您所在的企业/组织管理员选择开通、管理xibeicity服务的，我们会根据该企业/组织管理员的指令，记录该企业/组织用户的个人用户使用xibeicity相关功能/应用过程中提交或产生的信息和数据（下称“<span
                    class="bold line">企业/组织控制数据</span>”），企业/组织控制数据可能包括：
                </p>
                <p>
                  如您所在企业/组织管理员选择开通、管理和使用xibeicity企业通讯录、xibeicity智能办公应用服务的，
                  <span class="bold line">企业/组织用户可能提交或要求您提供：您的身份信息（姓名、生日、就职日期）、企业任职信息（部门、职位、职级、入职日期、员工编号）、企业及您个人的联系方式（手机、固定电话、邮箱）等企业分配给您的信息或您的其他个人信息。</span>
                </p>
                <p>
                  您所在企业/组织分配给您的职位、隶属部门、主营行业、<span class="bold">办公邮箱账号</span>、办公电话等信息，以及您为完成企业/组织日常经营管理开通的考勤、企信、审批、签到、日志、网盘、邮箱功能而要求提供或产生的
                  <span class="bold line">签到人脸照片、地理位置等个人敏感信息以及考勤打卡信息、审批记录、发布日志及分享信息、日程信息、IM信息、网盘文件信息</span>等。
                </p>
                <p class="bold line">
                  企业/组织用户在上传企业/组织通讯信息以及其他要求所在企业/组织的个人用户提交人事信息、外部业务联系人信息等个人信息之前，应事先获得个人用户的明确同意，并仅限收集实现企业/组织用户运营及管理目的所必需的个人用户信息，并确保已充分告知个人用户相关数据收集的目的、范围及使用方式等。
                </p>
                <p class="bold line">
                  您理解并同意，企业/组织用户为上述企业所控制数据的控制者，我们仅根据企业/组织用户管理员的指令开通、管理和使用上述应用服务处理企业/组织控制数据或您的个人信息。
                </p>
              </li>
              <li>
                <p class="bold">
                  2.6 您向我们提供的信息
                </p>
                <p>
                  在xibeicity服务使用过程中，您可以对xibeicity的产品、功能、应用及服务的体验反馈问题，帮助我们更好地了解您使用我们产品或服务的体验和需求，改善我们的产品或服务,为此我们会记录<span
                    class="bold line">您的联系信息、反馈的问题或建议</span>，以便我们进一步联系您反馈我们的处理意见。
                </p>
                <p>
                  如开通xibeicity部分功能需要您为所在企业/组织用户完成<span class="bold">实名认证的</span>，您除提交企业/组织用户名称及营业执照/组织机构代码证等企业文件，<span
                    class="bold">可能还需管理员及法定代表人姓名、身份信息、手机号码。</span>您理解并同意您在向我们提供该企业/组织用户信息及实际相关人员前述个人信息之前，<span
                    class="bold">已经取得充分授权同意。</span>
                </p>
              </li>
              <li>
                <p class="bold">
                  2.7 第三方向我们提供的信息
                </p>
                <p>
                  我们将对其他用户进行与您相关的操作时收集关于您的信息，亦可能从xibeicity关联公司、合作第三方或通过其他合法途径获取的您的个人数据信息。例如，为完成您的xibeicity账号注册，您同意运营商基于您个人的手机号码向xibeicity发送<span
                    class="bold line">短信验证码</span>，否则无法注册xibeicity账户。为在xibeicity客户端完成企业办公支付服务，我们需要支付公司提供您的<span class="bold line">支付账号信息（含昵称、头像、账户名）</span>以完成xibeicity客户端绑定支付账号。为完成xibeicity为了保障您的合法权益，防范欺诈、赌博等风险，维护xibeicity服务及支付相关功能的安全稳定运行，我们需要向第三方支付机构获取您实际使用的支付账户的<span
                    class="bold line">用户标识信息</span>。
                </p>
              </li>
              <li>
                <p class="bold">
                  2.8 客服及争议处理
                </p>
                <p>
                  当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的用户身份。
                </p>
                <p>
                  为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（包括账号信息、工单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和工单信息。
                </p>
                <p>
                  为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
                </p>
              </li>
              <li>
                <p class="bold">
                  2.9 为您提供评论、问答等信息公开发布功能
                </p>
                <p>
                  您可通过我们为您提供的工作圈、分享、日志等信息发布功能公开发布信息，包括作为xibeicity用户可发布图文、链接、发表评价及问答等内容。
                </p>
                <p>
                  请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息（例如在评价时选择上传包含个人信息的图片）、任何第三方（包括但不限于您所在的企业/组织用户）的商机秘密。请您在共享甚至公开分享相关信息之前确保已取得相关权利人明示同意。若您公开发布的信息中涉及儿童个人信息的，您需在发布前征得对应儿童监护人的同意。
                </p>
              </li>
              <li>
                <p class="bold">
                  2.10 其他
                </p>
                <p>
                  为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中（包括接入的第三方服务）可能会收集和使用您的个人信息。<span class="bold line">如果您不提供这些信息，不会影响您使用xibeicity数字化办公、沟通与协同等基本服务，</span>但您可能无法获得这些附加服务给您带来的用户体验。这些附加服务包括：
                </p>
                <ul>
                  <li>
                    2.10.1 允许访问通讯录并写入联系信息服务：为了保障体验，xibeicity接入了第三方服务商提供的扫名片服务，<span class="bold">在您授权访问并写入通讯录权限后，在您使用扫名片功能扫描名片时，在您手机本地通讯录写入你扫描的名片电话号码</span>。
                  </li>
                  <li>
                    2.10.2 基于相机/摄像头的附加服务：您可在开启相机/摄像头权限后使用扫码收款、AI图片智能识别、拍摄照片/小视频、视频会议、扫名片等功能。
                  </li>
                  <li>
                    2.10.3 基于存储，相册（图片库/视频库）的图片/视频访问及上传的附加服务：您可在开启相册权限后上传您的照片/图片/视频，以实现在xibeicity内更换<span
                      class="bold">头像</span>、分享<span class="bold">照片或视频</span>、<span class="bold">AI图片智能识别</span>、完成企业/组织认证或与客服沟通提供证明以及进行日志缓存，语音/图片/视频等多媒体下载等。
                  </li>
                  <li>
                    2.10.4 基于麦克风的语音技术相关附加服务：您可在开启麦克风权限后使用麦克风实现在xibeicity内的<span class="bold">单聊/群聊/写销售记录</span>中语音输入功能、视频会议功能，请您知晓，<span
                      class="bold line">即使您已同意开启麦克风权限，我们也仅会在您主动点击客户端内麦克风图标或录制视频时通过麦克风获取语音信息。</span>
                  </li>
                  <li>
                    2.10.5 基于日历的附加服务：在您开启我们可读取/写入您日历的权限后，我们将<span class="bold">收集您同步纷享邮箱日程、手机日程、日志日程</span>用于在xibeicity内创建您的日程以便在xibeicity日程功能中展示，管理您创建的日历信息、安排任务及提醒您相关日程事项安排。
                  </li>
                  <li>
                    2.10.6 位置权限：您可以打开您的位置权限(包括<span class="bold">地理位置权限或WIFI、识别范围</span>)，以完成您所在企业/组织安排的考勤打卡、外勤工作或您主动分享您所在位置。
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              您理解并同意，上述附加服务可能需要您在您的设备中开启您的位置信息 （地理位置）、摄像头（相机）、相册及存储、麦克风（语音）、日历的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备的
              “设置-应用权限”或客户端“我的-设置-通用或隐私”中查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。<span class="bold line">请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span>
            </p>
            <p class="bold">
              您理解并同意，我们向您提供的功能和服务场景是不断迭代升级的，如我们未在上述场景中明示您需要收集的个人信息，我们将会通过页面提示、交互设计等方式另行向您明示信息收集的内容、范围和目的并征得您同意。
            </p>
            <p>
              如我们停止运营xibeicity产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以提前30天以公告的形式通知您，<span class="bold">并依照所适用的法律对所持有的您的个人信息进行删除或匿名化处理</span>。
            </p>
          </section>
        </section>
        <section>
          <p class="bold">
            二、我们如何使用Cookie和同类技术
          </p>
          <p>
            为使您获得更轻松的访问体验，您使用xibeicity产品或服务时，我们可能会通过采用各种技术收集和存储您访问xibeicity服务的相关数据，在您访问或再次访问xibeicity服务时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务。包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入账户信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash
            Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
          </p>
          <p>
            请您理解，我们的全部服务只能通过使用Cookie才可得到实现。<span
              class="bold line">如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝xibeicity的Cookie</span>，但拒绝xibeicity的Cookie您将无法使用依赖于cookies的xibeicity服务。
          </p>
        </section>
        <section>
          <p class="bold">
            三、我们如何共享、转让、公开披露您的信息
          </p>
          <section>
            <p class="bold">
              (一)共享
            </p>
            <p>
              我们不会与您所在企业组织用户以外的公司、组织和个人共享您的个人信息，但以下情况除外：
            </p>
            <ul>
              <li>
                1.1 在获取您同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息，您确认共享的信息均为您有权披露和共享的信息。
              </li>
              <li>
                1.2 在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
              </li>
              <li>
                1.3 您主动选择情况下的共享：只有共享您的个人信息、个人敏感信息，才能提供您所要求的第三方产品和服务，如：
                <ul>
                  <li>
                    <p>
                      1.3.1 支付功能。在您通过xibeicity客户端企业办公支付功能实现收付、转账等支付相关功能时，您同意我们向支付公司提供您的<span class="bold line">个人基础信息（UserID、头像、昵称等信息</span>）及<span>身份信息（姓名）</span>以便开通或绑定支付账号；您同意在上述支付功能开通期间，授权我们获得您登录xibeicity账号所绑定的支付<span
                        class="bold line">账户信息（含昵称、头像、账户名）</span>，否则您将无法使用收付、转账等支付相关功能。<span class="bold line">您在使用支付公司的支付相关服务时，需阅读并遵守支付公司的协议和规则</span>。
                    </p>
                    <p class="bold">
                      为完成您在xibeicity上（例如订货通功能）的订单支付，您需要提供支付账户并选择付款方式，我们会将您或您所在企业组织绑定的xibeicity账户、对应的支付账户、订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息通过在应用程序中嵌入的支付公司软件工具开发包（SDK）等方式与支付公司共享。
                    </p>
                    <p class="bold">
                      如您选择由其他金融机构为您提供支付服务的，我们还会将您的包括银行卡号、有效期在内的银行卡支付必要信息与您选择的相应金融机构共享。
                    </p>
                    <p>
                      为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您同意我们可自您所选择的交易对象、支付公司或您选择的其他金融机构处收集与支付进度相关信息。
                    </p>
                  </li>

                  <li>
                    1.3.2
                    开通使用第三方应用。如您作为xibeicity企业组织用户管理员选择开通、管理并使用xibeicity集成或接入的第三方应用服务，您同意xibeicity向该第三方应用服务提供商提供您所在企业组织用户基于雇佣管理行为控制的企业组织员工<span
                      class="bold line">个人基础信息（UserID、头像、昵称等信息）及基础权限</span>，或您作为xibeicity个人用户，选择开通第三方小程序服务，您同意xibeicity向该小程序服务提供方提供您的个人基础信息（<span
                      class="bold line">UserID、头像、昵称等信息</span>），否则您将无法使用第三方应用服务或小程序服务。<span class="bold line">您开通使用第三方应用服务时，需阅读并充分了解并遵守第三方应用服务或小程序的产品功能、声明及相应隐私保护政策。</span>
                  </li>
                  <li class="bold">
                    1.3.3 侵权投诉处置：在您被他人投诉侵犯知识产权或其他合法权利时，我们依法需要向投诉人披露您的必要资料，以便进行投诉处理。
                  </li>
                  <li>
                    1.3.4 与关联公司间共享：<span class="bold line">为便于我们基于xibeicity账号共同向您提供服务，推荐您可能感兴趣的信息，识别账号异常，保护xibeicity关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。</span>我们只会共享必要的个人信息（如为便于您使用xibeicity账号使用我们关联公司产品或服务，我们会向关联公司共享您必要的账号信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
                  </li>
                  <li>
                    1.3.5
                    与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的，除非另行获得您的明示同意除外。对于涉及儿童个人信息的，我们不允许合作伙伴进行转委托。
                    <ul>
                      <li>
                        1)
                        供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术合作研发服务、云计算服务、提供xibeicity平台软件推广部署服务、提供客户服务、支付便利以及其他合作场景等。
                      </li>
                      <li>
                        <span class="bold">2)</span>
                        为了使您能够接收信息推送、使用地图服务、播放视频等必要的功能用途，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。<span class="bold">如您使用华为/小米/魅族手机时，华为/小米/魅族Push SDK需要读取您的设备识别号、联网相关信息，用于推送/下发通知栏消息；如您使用考勤/外勤服务，高德地图SDK需要您打开设备位置权限并收集设备所在位置相关信息信息、设备识别号、联网相关信息。</span>
                      </li>
                      <li>
                        3)
                        我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
          <section>
            <p class="bold">
              (二)转让
            </p>
            <p>
              我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
            </p>
            <ul>
              <li>
                2.1 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。
              </li>
              <li>
                2.2
                在xibeicity服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
              </li>
            </ul>
          </section>
          <section>
            <p class="bold">
              (三)公开披露
            </p>
            <p>
              我们仅会在以下情况下，公开披露您的个人信息：
            </p>
            <ul>
              <li>
                3.1 获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
              </li>
              <li class="bold line">
                3.2
                如果我们确定您出现违反法律法规或严重违反xibeicity相关协议规则的情况或为保护xibeicity及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或xibeicity相关协议规则披露关于您的个人信息，包括相关违规行为以及xibeicity已对您采取的措施。
              </li>
            </ul>
          </section>
          <section>
            <p class="bold">
              (四)共享、转让、公开披露个人信息时事先征得授权同意的例外
            </p>
            <p>
              以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
            </p>
            <ul>
              <li>
                4.1 与国家安全、国防安全有关的；
              </li>
              <li>
                4.2 与公共安全、公共卫生、重大公共利益有关的；
              </li>
              <li>
                4.3 与犯罪侦查、起诉、审判和判决执行等有关的；
              </li>
              <li>
                4.4 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
              </li>
              <li>
                4.5 您自行向社会公众公开的个人信息；
              </li>
              <li>
                4.6 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
              </li>
            </ul>
            <p>
              请您注意，根据法律规定，<span class="bold line">共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span>
            </p>
          </section>
        </section>
        <section>
          <p class="bold">
            四、我们如何保护您的信息
          </p>
          <section>
            <p class="bold">
              （一）技术安全防护
            </p>
            <p>
              为保障您的信息安全，我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的xibeicity客户端APP与服务器之间交换数据时受SSL协议加密保护；我们同时对xibeicity官网提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
            </p>
            <p>
              我们指定了专人负责儿童信息的保护，严格设定信息访问权限，对可能接触到儿童信息的工作人员采取最小够用授权原则，并采取技术措施对工作人员处理儿童信息的行为进行记录和管控，避免违法复制、下载儿童个人信息。
            </p>
          </section>
          <section>
            <p class="bold">
              （二）安全体系认证
            </p>
            <p>
              xibeicity有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。目前，我们的重要信息系统已经通过ISO27001：2013信息安全管理体系标准认证、ISO9001质量管理体系认证、网络安全等级保护三级测评认证。
            </p>
          </section>
          <section>
            <p class="bold">
              （三）人员安全管理
            </p>
            <p>
              我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。我方会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我方将尽力做到使您的信息不被泄漏、毁损或丢失。我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
            </p>
          </section>
          <section>
            <p class="bold">
              （四）存储地点及存储安全
            </p>
            <p class="bold">
              我们将在中华人民共和国境内运营xibeicity服务并将基于运营中收集和产生的个人信息存储在中华人民共和国境内。
            </p>
            <p>
              <span class="bold">我们会采取合理可行的措施存储您的个人信息，尽力避免收集无关的个人信息。</span><span class="bold line">我们只会在限于达成本政策所述目的所需的期限以及所适用法律法规所要求的期限内保留您的个人信息。</span>例如，xibeicity将依据《中华人民共和国电子商务法》的要求，就您开通和使用订货通服务中生成的交易商品、服务信息、交易信息，保存时间自交易完成之日起不少于三年；依据《中华人民共和国网络安全法》的要求，就您使用xibeicity应用服务留存相关的网络日志不少于六个月；依据《移动互联网应用程序信息服务管理规定》，就您的日志信息保存不少于六十日。而我们判断前述期限的标准包括：
            </p>
            <ul>
              <li>
                4.1 完成与您相关的服务目的、维护相应服务及业务记录、应对您可能的查询或投诉；
              </li>
              <li>
                4.2 保证我们为您提供服务的安全和质量；
              </li>
              <li>
                4.3 您是否同意更长的留存期间；
              </li>
              <li>
                4.4 是否存在保留期限的其他特别约定。
              </li>
            </ul>
            <p class="line">
              在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。
            </p>
          </section>
          <section>
            <p class="bold">
              （五）密码保护提示
            </p>
            <p>
              互联网并非绝对安全的环境，使用xibeicity服务时请使用复杂密码，协助我们保证您的账号安全。我们将合理商业努力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施非因不可抗力遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
            </p>
          </section>
          <section>
            <p class="bold">
              （六）信息安全事件处理
            </p>
            <p>
              发生个人信息安全事件后，我们将按照法律法规的要求（最迟不迟于30
              个自然日内）向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
            </p>
          </section>
          <section>
            <p class="bold">
              （七）儿童个人信息安全
            </p>
            <p>
              我们已制定儿童信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生儿童信息安全事件后，我们将按照法律法规的要求，及时向儿童及其监护人告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、儿童及其监护人可自主防范和降低风险的建议、对儿童及其监护人的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息等方式告知儿童及其监护人。难以逐一告知时，我们会采取合理、有效的方式发布相关警示信息。同时，我们还将按照监管部门要求，主动上报儿童信息安全事件的处置情况。若儿童及其监护人的合法权益受损，我们将按照法律规定承担相应的法律责任。
            </p>
            <p class="bold line">
              互联网环境并非百分之百安全，尽管我们有这些安全措施，但请注意在互联网上不存在“完善的安全措施”，我们将基于正常的商业努力确保您的信息的安全。
            </p>
          </section>
        </section>
        <section>
          <p class="bold">
            五、您如何管理您的信息
          </p>
          <p>
            您可以通过以下方式访问及管理您的信息：
          </p>
          <section>
            <p class="bold">
              （一）查询、更正和补充您的信息
            </p>
            <p>
              1.1 xibeicity企业/组织用户
            </p>
            <p>
              作为xibeicity企业/组织用户的管理员，您有权查询、更正或补充您所在企业/组织的信息。您可以通过以下方式自行进行：
            </p>
            <p>
              打开xibeicity官方主页（<a class="bold" href="https://www.xibeicity.com/">xibeicity.com</a>），选择右上角“用户登录”后通过企业/组织输入账号、密码后，选择企业/组织，登陆“xibeicity”，点击“管理”菜单，经二次验密后进入企业管理界面后，可以查询、更正企业/组织控制信息，包括通讯录信息、邀请短信设置、修改登陆密码、更换主管理员、设置子管理员等。您也可以通过“应用”选择开通或关闭xibeicity基础应用、第三方应用或自建应用。
            </p>
            <p>
              1.2 xibeicity个人用户
            </p>
            <p>
              作为xibeicity个人用户，您有权查询、更正或补充您的信息。您可以通过以下方式自行进行：
            </p>
            <p>
              登录xibeicity手机客户端，通过操作“我-设置-个人资料”可以查询、更正个人资料及个人账户相关信息：姓名、头像、昵称、电话、性别、工作描述、邮箱、微信、QQ等信息。
            </p>
            <p>
              登录手机客户端，通过以下路径“我-设置-关于xibeicity-客服电话”通过拨打xibeicity客服热线（4001122778）（5*9小时（9:00-18:00））寻求帮助，协助您查询、更正或补充您的信息。
            </p>
          </section>
          <section>
            <p class="bold">
              （二）删除您的信息
            </p>
            <p>
              您可以通过上述“（一）查询、更正和补充您的信息”中列明的方式或通过您所属的企业/组织用户管理员操作删除您的部分信息。
            </p>
            <p>
              在以下情形中，您可以向我们提出删除个人信息的请求：
            </p>
            <ul>
              <li>
                2.1 如果我们处理个人信息的行为违反法律法规；
              </li>
              <li>
                2.2 如果我们处理个人信息的行为严重违反了与您的约定；
              </li>
              <li>
                2.3 如果您所在的企业/组织用户不再使用我们的产品或服务；
              </li>
              <li>
                2.4 如果我们永久不再为您提供产品或服务。
              </li>
            </ul>
            <p>
              若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）
            </p>
            <p>
              当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其进一步处理隔离，直到备份可以清除或实现匿名。
            </p>
          </section>
          <section>
            <p class="bold">
              （三）改变您授权同意的范围
            </p>
            <p>
              每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一部分”），除此之外，您可以操作改变您的授权同意范围：
            </p>
            <ul>
              <li>
                3.1
                作为企业/组织管理员，您可以通过打开xibeicity官方主页（xibeicity.com），选择右上角“用户登录”后通过输入账号、密码后，选择企业/组织，登陆“xibeicity”，点击“管理”菜单，经二次验密后进入企业管理界面后，企业/组织通过“应用”选择开通或关闭xibeicity基础应用、第三方应用或自建应用。
              </li>
              <li>
                3.2 作为xibeicity个人用户，您可以在登录手机客户端，通过“我-设置-隐私”中设置或与xibeicity网客服联系或改变您的智能移动设备的设置等方式给予或收回您的授权同意。
              </li>
            </ul>
            <p>
              当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
            </p>
          </section>
          <section>
            <p class="bold">
              （四）注销您的账户
            </p>
            <p class="bold">
              当您所在企业/组织用户注销xibeicity企业/组织账户时，我们将一并注销您的个人用户账户并将根据适用法律的要求删除您的个人信息或做匿名化处理。
            </p>
            <ul>
              <li>
                4.1 <span class="bold">如您所在企业组织用户为付费用户，若购买的xibeicity产品服务因到期而停用达到30天仍未续费申请复用的，则我们有权注销该企业账户；如您所在企业组织用户为免费用户，xibeicity将依据《xibeicity服务协议》收回账户后注销；您所在企业组织用户</span>也可以拨打客服热线（4001122778）（5*9小时（9:00-18:00））主动申请账户注销，我们将协助注销账户。
              </li>
              <li>
                4.2 您可以向所在企业组织管理员申请注销个人账户或在<span class="bold">您所在企业组织账户注销的同时您的个人账户将一并注销</span>。
              </li>
            </ul>
          </section>
          <section>
            <p class="bold">
              （五）约束信息系统自动决策
            </p>
            <p>
              在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害xibeicity商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
            </p>
          </section>
          <section>
            <p class="bold">
              （六）响应您的上述请求
            </p>
            <p>
              为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
            </p>
            <p>
              我们将在15天内做出答复。如您不满意，还可以通过xibeicity客服发起投诉。
            </p>
            <p>
              对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
            </p>
            <p>
              在以下情形中，按照法律法规要求，我们将无法响应您的请求：
            </p>
            <ul>
              <li>
                6.1 与国家安全、国防安全有关的；
              </li>
              <li>
                6.2与公共安全、公共卫生、重大公共利益有关的；
              </li>
              <li>
                6.3 与犯罪侦查、起诉、审判和执行判决等有关的；
              </li>
              <li>
                6.4 有充分证据表明个人信息主体存在主观恶意或滥用权利的；
              </li>
              <li>
                6.5 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
              </li>
              <li>
                6.6 涉及xibeicity企业/组织用户商业秘密的。
              </li>
            </ul>
          </section>
        </section>
        <section>
          <p class="bold">
            六、我们如何处理儿童的信息
          </p>
          <p>
            在数字化办公、沟通与协同活动中我们推定您具有相应的民事行为能力。如您为儿童，我们要求您请您的父母或其他监护人仔细阅读本隐私政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。
          </p>
          <p>
            对于经父母或其他监护人同意使用我们的产品或服务而收集儿童个人信息的情况，我们只会在法律法规允许、父母或其他监护人明确同意或者保护儿童所必要的情况下使用、共享、转让或披露此信息。
          </p>
          <p class="bold line">
            若您是儿童的父母或其他监护人，请您关注您监护的儿童是否是在取得您的授权同意之后使用我们的服务。如您对您所监护的儿童的个人信息有疑问，请通过第九节中的联系方式与我们联系。
          </p>
        </section>
        <section>
          <p class="bold">
            七、本隐私政策如何更新
          </p>
          <p>
            我们的隐私政策可能变更。
          </p>
          <p>
            未经您明确同意我们不会限制您按照本隐私政策所应享有的权利。我们会在xibeicity官方主页（www.xibeicity.com）或系统站内通知上公告发布对本隐私政策所做的任何变更。
          </p>
          <p>
            对于重大变更，我们还会提供更为显著的通知（包括我们会通过xibeicity公示的方式进行意见征集、公告通知甚至向您提供弹窗提示）。
          </p>
          <p>
            本政策所指的重大变更包括但不限于：
          </p>
          <ul>
            <li>
              7.1 我们的服务模式发生重大变化。如处理个人信息的目的、处理个人信息的类型以及个人信息的使用方式等；
            </li>
            <li>
              7.2 我们在控制权、组织架构等方面发生重大变化。如并购重组等引起的控制者变更等；
            </li>
            <li>
              7.3 个人信息共享、转让或公开披露的主要对象发生变化；
            </li>
            <li>
              7.4 您参与个人信息处理方面的权利及其行使方式发生重大变化；
            </li>
            <li>
              7.5 我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；
            </li>
            <li>
              7.6 用户信息安全影响评估报告表明存在高风险时。
            </li>
          </ul>
        </section>
        <section>
          <p class="bold">
            八、如何联系我们
          </p>
          <p>
            如果您对xibeicity的隐私保护政策或数据处理有任何疑问、意见或建议，可以通过“我-设置-关于xibeicity-客服电话”拨打（<span class="bold">4001122778</span>）（5*9小时（9:00-18:00））与我们联系，我们邮寄联系方式如下：
          </p>
          <p class="bold">
            北京易动纷享科技有限责任公司
          </p>
          <p>
            联系部门：客户服务部
          </p>
          <p>
            电子邮箱：service@xibeicity.com
          </p>
          <p>
            联系地址：*****
          </p>
          <p>
            一般情况下，我们将在收到您相关联系信息并核实您身份后两个工作日内回复。
          </p>
          <p>
            如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，<span class="bold line">您还可以通过向北京海淀区有管辖权的法院提起诉讼来寻求解决方案</span>。
          </p>
        </section>
      </section>
    </article>


  </div>
</template>

<script>
export default {
  name: "privacy"
}
</script>

<style scoped>

* {
  padding: 0;
  margin: 0;
}

body {
  padding: 6%;
  line-height: 1.5;
  font-size: 14px;
}

h1 {
  font-size: 1.5em;
  text-align: center;
  font-weight: 400;
}

summary {
  display: block;
  padding: 1em 0 0.6em 0;
}

h2 {
  padding: 0.8em 0;
  font-size: 1.1em;
}

h3 {
  padding: 0.8em 0;
  font-size: 1em;
}

p {
  padding: 0.5em 0;
}

.time {
  text-align: right;
}

.bold {
  font-weight: 700;
}

.line {
  text-decoration: underline;
}

ul {
  list-style: none;
}

li {
  list-style: none;
  padding: 0.3em 0;
}

a {
  text-decoration: none;
}
</style>