<template>
  <div class="website-footer">
    <div class="website-footer-box">
      <div class="beian">
<!--        <el-link target="_blank" :underline="false" href="https://beian.miit.gov.cn/">京ICP备16015368号-4</el-link>-->
<!--        &nbsp;&nbsp;&nbsp;&nbsp;-->
        <div class="beian-pp">Copyright © {{ new Date().getFullYear() }} 电子教材阅读系统 All Rights Reserved</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="less" scoped>

.website-footer {
  width: 100%;
  background: #111010;

  .website-footer-box {
    width: 1200px;
    margin: 0 auto;

    .beian {
      color: #ffffff;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-content: center;
      height: 40px;
      line-height: 39px;
      border-top: 1px solid #232323;
      box-sizing: border-box;
      font-size: 14px;
      justify-content: center;

      .el-link {
        color: #ffffff;
        font-size: 14px;
      }

      .beian-div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
</style>