/**
 * Created by superman on 17/2/16.
 * http配置
 */

import axios from 'axios'
import store from './store/index'
import * as types from './store/type'
import MoLiConst from './const.js'
import router from './router'
// axios 配置
axios.defaults.timeout = 50000
axios.defaults.baseURL = '/'
import {Message, Loading} from 'element-ui';
import JSEncrypt from "jsencrypt";
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let loading;

function startLoading() {    //使用Element loading-start 方法
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.3)'
    })
}

function endLoading() {    //使用Element loading-close 方法
    setTimeout(function () {
        loading.close()
    }, 300)

}

let needLoadingRequestCount = 0

export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}

let encrypt = new JSEncrypt();
let PUBLICKEY = MoLiConst.PUBLICKEY;
encrypt.setPublicKey(PUBLICKEY);
// http request 拦截器
axios.interceptors.request.use(
    config => {
        config.headers.APPID = MoLiConst.APPID;
        config.headers.SECRET = MoLiConst.SECRET;
        if (store.state.access_token) {
            config.headers.Authorization = `${store.state.access_token}`
        }
        if (config.method == 'post') {
            config.data = {
                ...config.data,
                _t: Date.parse(new Date()),
                _v: '1.0',
                _w: window.screen.width,
                _h: window.screen.height,
            }
        }
        if (config.method == 'get') {
            config.params = {
                _t: Date.parse(new Date()),
                _v: '1.0',
                ...config.params
            }
        }
        showFullScreenLoading()
        return config
    },
    err => {
        return Promise.reject(err)
    },
)

// http response 拦截器
axios.interceptors.response.use(
    response => {
        tryHideFullScreenLoading()
        if (response.data.code && response.data.code == 401) {
            store.commit(types.LOGOUT)
            // 只有在当前路由不是登录页面才跳转
            router.currentRoute.path !== 'login' &&
            router.replace({
                path: '#/',
                // query: {redirect: router.currentRoute.path},
            })
        } else {
            return response.data
        }
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 401 清除token信息并跳转到登录页面
                    store.commit(types.LOGOUT)
                    // 只有在当前路由不是登录页面才跳转
                    router.currentRoute.path !== 'login' &&
                    router.replace({
                        path: '#/login',
                        // query: {redirect: router.currentRoute.path},
                    })
                    break;
                case 403:
                    Message.error('无访问权限');
                    break;
                case 404:
                    Message.error('网络资源不存在');
                    break;
                case 500:
                    Message.error('网络资源错误');
                    break;
            }
        }
        // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
        tryHideFullScreenLoading();
        return Promise.reject(error.response)
    },
)

export default axios
