<template>
  <div style="padding: 20px">
    <h1>xibeicity服务协议</h1>
    <summary class="time bold">更新时间：<span class="line">2021年6月30日</span></summary>
    <summary class="time bold">生效时间：<span class="line">2021年7月30日</span></summary>

    <p class="bold">
      欢迎使用xibeicity产品及服务！
    </p>

    <p class="bold">
      提示：在使用xibeicity产品及服务之前，您应当认真阅读并遵守《xibeicity服务协议》（以下简称“本协议”）以及《xibeicity隐私政策》。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。免除或者限制责任的条款可能将以加粗字体显示，您应重点阅读。
    </p>

    <p class="bold">
      当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他xibeicity允许的方式实际使用本服务时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得xibeicity对您问询的解答等理由，主张本协议无效或要求撤销本协议。
    </p>

    <section>
      <h2>
        一、缔约主体
      </h2>
      <p>
        本协议由通过xibeicity网站、移动客户端及PC端xibeicity应用软件使用xibeicity服务的用户（以下简称<span class="bold">“用户”</span>或<span class="bold">“您”</span>）与xibeicity（以下合称<span
          class="bold">“xibeicity公司”</span>、<span class="bold">“xibeicity”</span>或<span class="bold">“我们”</span>）共同缔结。
      </p>
    </section>

    <section>
      <h2>
        二、协议内容和效力
      </h2>
      <ul>
        <li>
          <span class="bold">2.1</span>
          本协议内容包括本协议正文及所有我们已经发布或将来可能发布的隐私权政策、各项政策、规则、声明、通知、警示、提示、说明（以下简称“规则”）。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。
        </li>
        <li class="bold">
          2.2
          xibeicity公司有权根据需要不时制订、修改本协议及相关规则，变更后的协议和规则将通过xibeicity官方网站（链接：www.xibeicity.com）或系统站内，提前七（7）天公告的形式通知您并经公告完毕后即取代原协议及规则生效。如您不同意相关变更，应当立即停止使用xibeicity服务；如您继续使用xibeicity服务或进行任何网站活动，即表示您已接受经修订的协议和规则。
        </li>
      </ul>
    </section>

    <section>
      <h2>
        三、服务内容
      </h2>
      <ul>
        <li>
          <p>
            <span class="bold">3.1 xibeicity产品和服务</span>系我司自主开发、研制的，基于“xibeicity”平台（<span class="bold">www.xibeicity.com</span>）将CRM、PRM及SCRM融为一体，为企业提供营销管理、销售管理、订单管理、服务管理、伙伴管理等业务数字化体系建设服务。这些功能服务可能根据用户需求的变化，随着因服务版本不同、或服务提供方的单方判断而被优化或修改，或因定期、不定期的维护而暂缓提供。<span
              class="bold">xibeicity公司有权自行决定对服务或服务任何部分及其相关功能、应用软件进行变更、升级、修改、转移，并在xibeicity官网上公示通知。</span>xibeicity公司会不断丰富您使用本服务的终端、形式等，如您已注册使用一种形式的本服务，则可以以同一账号使用其他形式的本服务，本协议自动适用于您对所有版本的xibeicity软件和服务的使用。
          </p>
          <p class="bold line">
            本协议不适用于第三方通过前述网页或xibeicity客户端直接向您提供的服务（统称“第三方服务”），如您通过xibeicity提供的第三方应用功能入口，开通使用的第三方应用服务（扫名片、扫码收款、呼叫中心功能、直播、AI图片智能识别、电子签章、视频会议等）；如您通过xibeicity客户端绑定支付公司提供的支付、转账等支付相关功能。
          </p>
        </li>
        <li>
          <span class="bold">3.2</span> xibeicity公司依据本协议、《xibeicity隐私政策》以及其他适用的规则，<span class="bold">许可您对xibeicity软件进行非独占、非排他、不得分许可、不得再分发的一般使用权。</span>本协议未明示授权的其他一切权利仍由xibeicity公司保留，您在行使这些权利时须另外取得xibeicity公司的书面许可。xibeicity公司如果未行使前述任何权利，并不构成对该权利的放弃。
        </li>
        <li>
          <span class="bold">3.3</span>
          企业组织用户申请的管理员账号，可上传和管理您所在企业组织的企业组织通讯录，邀请企业通讯录内的企业组织成员加入相应企业组织群组，并通过xibeicity服务实现企业组织内部成员沟通、工作互动、协同智能办公等。基于xibeicity的互联功能，企业组织管理员可通过授权与指定的xibeicity现有用户互联的方式创建该企业组织用户的外部用户（下称“互联外部用户”）；或将xibeicity现有用户之外的且非本企业组织成员的外部人员创建为该企业组织用户的外部用户（下称“分配外部用户”）<span
            class="bold">企业组织及授权的企业管理员在对企业组织成员、分配外部用户进行身份管理之前，应向对应的企业组织成员及分配外部用户充分说明基于企业管理需求所开通的xibeicity相关服务。</span>
        </li>
        <li>
          <span class="bold">3.4</span>
          如果您受某个企业组织用户邀请并被创建成为该企业组织的成员并维持该身份时，您可以享受xibeicity为该企业组织及其成员提供的工作圈服务内容，包括成员通讯录、企信服务、智能化办公服务等（以xibeicity公司实际提供功能及企业组织或群组开通情况为准）；如果您受某个企业组织用户邀请并被创建成为该企业组织的分配外部用户并维持该身份时，您可以使用该企业组织实际为您开通的功能。<span
            class="bold">您所在企业组织及其管理员基于企业管理需求，有权对您的身份及基于该身份的相关权益进行管理，包括但不限于加入、删除以及调整权限和限制服务内容等。您保证在遵守xibeicity服务规范的同时，按照企业组织的授权范围和规范使用xibeicity服务。</span>
        </li>
        <li class="bold">
          3.5
          鉴于xibeicity产品为面向企业组织用户，基于办公场景下的办公效能提升工具，因此企业组织用户及其组织成员用户、该企业组织创建的分配外部用户在“xibeicity”创建或产生的数据的所有权归属于该企业组织用户。在服务期内企业组织用户的系统管理员有权对数据进行任何形式的处置，包括从平台中复制，导出和删除。
        </li>
      </ul>
    </section>

    <section>
      <h2>
        四、注册及账号管理
      </h2>
      <ul>
        <li>
          <span class="bold">4.1</span> 您确认，如您代表一家企业、组织机构或其他法律主体（下称“企业组织”）申请注册企业组织用户，则您声明和保证，<span class="bold">您已充分获得来自该企业组织的授权，有权代表该企业组织申请xibeicity服务并同意受本协议约束</span>。您确认，企业组织用户首次登陆xibeicity时，应完成首位管理员账号注册，如您代表企业组织申请注册首位管理员用户，则您声明和保证，<span
            class="bold">您已充分获得来自该企业组织的授权及该账号个人用户的授权，有权代表该企业组织及个人用户申请xibeicity服务并同意受本协议约束</span>。您确认，如您作为企业组织管理员用户，邀请并创建企业组织成员用户使用本服务，则您声明和保证，<span
            class="bold">您已经充分获得来自该企业组织及企业组织成员的授权，有权代表该企业组织及企业组织成员申请xibeicity服务并同意受本协议约束</span>。您确认，如您作为企业组织管理员用户，邀请并创建上述外部用户使用本服务，则您声明和保证，<span
            class="bold">您已经充分获得来自该企业组织及外部用户的授权，有权代表该企业组织及外部用户申请xibeicity服务并同意受本协议约束；您已充分理解并知晓开通和使用互联功能的风险和后果，并同意对于您与外部用户之间基于互联功能使用引发的任何纠纷，xibeicity不承担任何责任，如导致xibeicity任何损失（包括但不限于任何赔偿、直接或间接损失），您同意赔偿xibeicity全部损失</span>。如您在您完成注册程序或以其他xibeicity公司允许的方式实际使用服务时，<span
            class="bold">您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且xibeicity公司有权注销您的账户，并向您及您的监护人索偿。</span>
        </li>
        <li>
          <span class="bold">4.2 如您为企业组织用户，则您须提供企业名称、营业执照、负责人手机号等完成注册。如您为企业组织管理员用户，则您须提供企业名称、用户姓名、手机号等完成注册。</span>如您作为企业组织管理员为企业组织成员、分配外部用户创建账号，则您须提供<span
            class="bold">用户手机号及姓名</span>完成账号创建。您注册及创建时应提交真实、准确、完整和反映当前情况的身份及其他相关信息。<span class="bold">你承诺账号中设置的企业名称、企业服务号、头像和简介等信息中不存在违法、不良、冒充他人信息、未经许可为他人注册、以可能导致其他用户误认的方式注册账号或设置企业服务号或使用可能侵犯他人权益的名称（包括但不限于涉嫌商标权、名誉权侵权等），否则xibeicity公司有权不予注册或停止服务并收回账号及企业服务号，因此产生的损失由您自行承担。</span>
        </li>
        <li>
          <span class="bold">4.3</span> 您了解并同意，xibeicity注册账号（包括客户端账号及服务管理账号）所有权归属于xibeicity公司，注册完成后，<span class="bold">您仅获得账号使用权。xibeicity账号使用权仅归属于初始申请注册人，除非法律规定或司法裁定或征得xibeicity公司的书面同意，您的账户、密码不得以任何方式出租、转让、赠与或继承（与账户相关的财产权益除外）或被提供予他人使用，否则，xibeicity公司有权立即收回该账号，由此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，您应自行承担。</span>
        </li>
        <li>
          <span class="bold">4.4</span>
          在您成功注册后，xibeicity将根据您的身份要素识别您的身份和授权登录。“身份要素”包括但不限于您的账户名称、密码、短信校验码、手机号码。您同意基于不同的终端以及您的使用习惯，xibeicity可能采取不同的验证措施识别您的身份。例如您的账户在新设备首次登录的，我们可能通过密码加短信校验码的方式确认您的身份。<span
            class="bold">请您务必妥善保管您的终端及账户、密码和/或身份要素信息，并对利用该账户和密码/身份信息所进行的一切活动（包括但不限于网上点击同意或提交各类规则协议或支付购买、分享资讯或图片、发起电话会议等）负全部责任。您承诺，在密码或账户遭到未获授权的使用，或者发生其他任何安全问题时，将立即通知xibeicity公司，且您同意并确认，除法律另有规定外，xibeicity公司不对上述情形产生的任何直接或间接的遗失或损害承担责任。</span>
        </li>
        <li>
          <span class="bold">4.5</span> 您了解并同意，<span class="bold">若您购买的xibeicity产品服务因到期停用达到30天仍未续费申请复用且我们与您无特殊约定的，则我们有权注销该企业组织账号；如您为免费试用企业组织用户且注册后7天内未激活使用或连续15天未登陆使用，xibeicity公司为优化管理等目的，有权回收（注销）该账户；</span>如您为企业组织用户，您也可以拨打客服热线（4001122778）（5*9小时）主动申请账户注销，我们将协助您注销您的账户。如<span
            class="bold">您为企业组织用户下的个人账户（成员账户或分配外部账户）</span>，您可以向所在企业组织管理员申请注销个人账户或在<span class="bold">您所在企业组织账户注销的同时您的个人账户将一并自动注销。如企业组织用户注销（主动或被动注销），xibeicity将对账户相关内容及信息依法删除</span>，包括但不限于取消和删除该企业组织用户及其企业组织成员、分配外部用户基于该企业组织的一切信息和相关权益、所创建用户的个人信息，<span
            class="bold">因账户注销由此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，xibeicity无需向相关用户承担任何责任。您或您代表的企业组织也有义务及时删去您存储在xibeicity服务器上的所有信息。</span>
        </li>
      </ul>
    </section>

    <section>
      <h2>
        五、服务使用规范
      </h2>
      <ul>
        <li>
          <span class="bold">5.1 您应自行对利用xibeicity服务从事的所有行为及结果承担责任。</span>相应地，您应了解，使用xibeicity服务可能发生来自他人非法或不当行为（或信息）的风险（例如来自交易相对方的信用风险、侵权风险），您应自行判断及行动，并自行承担相应的风险。
        </li>
        <li>
          <span class="bold">5.2 您应自行承担因使用服务所产生的费用，包括但不限于上网流量费、通信服务费等。</span>
        </li>
        <li>
          <span class="bold">5.3</span> 您承诺<span class="bold">不对xibeicity服务任何部分或xibeicity服务之使用或获得，进行复制、拷贝、出售、转售或用于任何侵犯xibeicity知识产权、商业秘密、合法权益的商业目的</span>，包括但不限于如下行为：删除或修改xibeicity服务平台上的版权信息，或者伪造ICP/IP地址或者数据包的名称；进行编译、反编译、反向工程或者以其他方式破解xibeicity服务平台的行为。
        </li>
        <li>
          <span class="bold">5.4</span> 您承诺不会利用本服务进行任何违法或不当的活动，包括但不限于下列行为:
          <ul>
            <li>
              <ul>
                <span class="bold">5.4.1</span>上载、传送或分享含有下列内容之一的信息：
                <li class="bold">
                  (a) 反对宪法所确定的基本原则的；
                </li>
                <li class="bold">
                  (b) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
                </li>
                <li class="bold">
                  (c) 损害国家荣誉和利益的；
                </li>
                <li class="bold">
                  (d) 煽动民族仇恨、民族歧视、破坏民族团结的；
                </li>
                <li class="bold">
                  (e) 破坏国家宗教政策，宣扬邪教和封建迷信的；
                </li>
                <li class="bold">
                  (f) 散布谣言，扰乱社会秩序，破坏社会稳定的；
                </li>
                <li class="bold">
                  (g) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                </li>
                <li class="bold">
                  (h) 侮辱或者诽谤他人，侵害他人合法权利的；
                </li>
                <li class="bold">
                  (i) 含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；
                </li>
                <li class="bold">
                  (j) 含有中国或其您所在国国家管辖法所适用的法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；
                </li>
              </ul>
            </li>
            <li class="bold">
              5.4.2 冒充任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
            </li>
            <li>
              <span class="bold">5.4.3 </span> 伪造标题或以其他方式操控识别资料，<span class="bold">使人误认为该内容为xibeicity公司或其关联公司所传送；</span>
            </li>
            <li class="bold">
              5.4.5 将涉嫌侵害他人权利（包括但不限于著作权、专利权、商标权、商业秘密等知识产权、隐私权）之内容上载、传送或分享；
            </li>
            <li class="bold">
              5.4.6 跟踪或以其它方式骚扰他人, 或通过xibeicity服务向xibeicity用户或任何终端接收方发送大量信息；
            </li>
            <li class="bold">
              5.4.7
              将任何广告、推广信息、促销资料、“垃圾邮件”、“滥发信件”、“连锁信件”、“直销”或其它任何形式的劝诱资料加以上载、传送、分享、发送、推送，但在为前述目的专用区域或专用功能，依照《广告法》等相关法律规定使用该专用功能的情况除外；
            </li>
            <li>
              <span class="bold">5.4.8</span> 因xibeicity服务并非为某些特定目的而设计，除您与我们另有约定外，<span class="bold">您不可将本服务用于包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，xibeicity公司不承担法律责任；</span>
            </li>
            <li>
              <span class="bold">5.4.9</span>
              违反遵守法律法规（中国法律、法规、规章、政策及规范性文件）、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的行为；
            </li>
          </ul>
        </li>
        <li>
          <span class="bold">5.5 您承诺不对本软件和服务从事以下行为：</span>
          <ul>
            <li>
              <span class="bold">5.5.1</span>
              将有关干扰、破坏或限制xibeicity软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案、方法和程序之资料，加以上载、张贴、发送电子邮件或以其他方式向公众传播，无论这些行为是否为商业目的；
            </li>
            <li>
              <span class="bold">5.5.2</span> 干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；
            </li>
            <li>
              <span class="bold">5.5.3 </span> 通过修改或伪造xibeicity软件运行中的指令、数据，增加、删减、变动、干扰xibeicity软件的功能或运行效果；
            </li>
            <li>
              <span class="bold">5.5.4</span> 通过非xibeicity公司开发、授权的第三方软件、插件、外挂、系统，登录或使用xibeicity，或制作、发布、传播上述工具；
            </li>
            <li>
              <span class="bold">5.5.5</span>
              利用技术非法侵入、破坏xibeicity服务平台之服务器系统，或者修改、增加、删除、窃取、截留、替换xibeicity服务平台之客户端或服务器系统中的数据，或者非法挤占xibeicity服务平台之服务器空间，或者实施其他的使之超负荷运行的行为。
            </li>
          </ul>
        </li>
        <li>
          <span class="bold">5.6</span>
          您承诺不以任何形式侵犯xibeicity公司的商业利益，或从事任何可能对xibeicity造成损害或不利于xibeicity的行为，包括但不限于不得进行任何破坏xibeicity服务平台使用公平性或者其他影响其正常使用秩序的行为，如利用BUG（又叫“漏洞”或者“缺陷”）来获得不正当的非法利益。
        </li>
        <li>
          <span class="bold">5.7</span>
          您了解并同意，如您在使用xibeicity服务过程中，收到来自xibeicity公司及其关联公司自行或其他第三方合作伙伴发送的广告、推广或宣传信息（包括商业或非商业信息）的，xibeicity公司将依照法律的规定履行相关义务（包括向您提供选择关闭广告信息的功能），您应当自行判断广告信息的真实性可靠性并为自己的判断行为负责；<span
            class="bold">除法律明确规定外，您因依该广告信息进行的交易或前述广告主提供的内容而遭受的损失或损害，xibeicity公司不承担责任。</span>
        </li>
        <li>
          <span class="bold">5.8</span> 如您作为企业组织用户申请管理员账号或子管理员账号，则应自行添加、指定或变更管理成员和/或子管理员，并对管理成员的行为自行承担责任；<span
            class="bold">xibeicity根据您管理员/子管理员账号的行为对所在企业组织成员服务的调整亦由您承担后果，因此给xibeicity公司或他人造成损失的，您应当予以赔偿。</span>
        </li>
        <li>
          <span class="bold">5.9</span> 如您在xibeicity客户端使用支付公司提供的支付功能：例如<span class="bold">收付、转账等功能的，应当依照法律、法规等规范性文件的规定开展，不得以非法方式使用收付、转账功能，也不得将支付、转账功能用于实施非法活动</span>，例如实施赌博、非法吸收公众存款、集资诈骗、开展传销等违法犯罪行为，或为违法犯罪行为提供协助。因此给xibeicity公司或他人造成损失的，您应当予以赔偿。
        </li>
        <li>
          <span class="bold">5.10 您充分了解并同意，您必须为您帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。</span>您应对使用本服务时接触到的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。<span
            class="bold">xibeicity公司无法且不会对您因前述风险而导致的任何损失或损害承担责任。</span>
        </li>
        <li>
          <span class="bold">5.11</span> 您承诺，使用xibeicity服务时您将严格遵守本协议，并就违反本协议行为承担全部责任。鉴于xibeicity为面向企业用户，基于办公场景下提升办公效能的工具，作为企业组织用户的<span
            class="bold">您知晓并同意：您管控的企业组织成员用户、管理员用户及您创建的分配外部用户违反本协议的，您将承担连带责任。</span>
        </li>
      </ul>
    </section>

    <section>
      <h2>
        六、服务费用
      </h2>
      <ul>
        <li class="bold">
          6.1
          本服务的任何免费试用或免费功能和服务不应视为xibeicity公司放弃后续收费的权利。xibeicity公司有权提前7天在xibeicity官网上和/或具体xibeicity服务应用内以公告的形式通知您收费标准及收费方式。除我们与您另有约定外，若您继续使用相关xibeicity服务，则需按xibeicity公司公布的收费标准支付相应费用。
        </li>
        <li>
          <span class="bold">6.2</span> 所有费用需通过xibeicity接受的支付方式事先支付。<span
            class="bold">前述使用费不包含相关汇款等支出，否则您应补足付款或自行支付该费用。</span>
        </li>
        <li class="bold">
          6.3
          xibeicity有权根据实际情况提前7天单方调整费用标准及收费方式（但我们与您另有约定除外），并以公告形式通知您，而无需获得您的事先同意。除我们与您另有约定外，如您不同意收费应当立即停止服务的使用，否则使用则视为您已同意并应当缴纳费用。
        </li>
        <li class="bold">
          6.4 您应当自行支付使用本服务可能产生的上网费以及其他第三方收取的通讯费、信息费等。
        </li>
        <li class="bold">
          6.5 您理解并同意，在使用本软件中的多方通信服务、语音服务及培训助手服务时，xibeicity服务需通过电信运营商及或云服务商提供的网络提供服务，xibeicity公司及或电信运营商、云服务商将向您收取一定通信费用。
        </li>
      </ul>
    </section>

    <section>
      <h2>
        七、第三方应用及服务
      </h2>
      <p class="bold">
        xibeicity与部分第三方应用合作，可能包含能跳转到第三方所提供的在线服务或资源的链接，或能让您直接访问该等服务或资源，以便您选购开通、管理和使用第三方应用：xibeicity接入支付公司为您提供收付、转账、扫码收款等支付功能；接入视频会议服务商为您提供视频会议服务；接入科大讯飞为您提供企信内选择语音转文字功能服务；接入百度地图、高德地图等地图服务商以便您使用xibeicity的定位功能；以及接入第三方提供的培训直播、扫名片、AI图片识别、电子签章、呼叫中心等。您了解并同意，xibeicity仅为了您便利操作而提供该功能模块或服务订购和/或使用入口，相关服务由前述第三方提供，我们无法控制您对该等第三方服务或资源的使用，除法律法规和本协议另有规定外，xibeicity公司不对您对该应用的使用承担任何责任。
      </p>
      <ul>
        <li>
          <span class="bold">7.1</span> 您了解并同意，除法律另有明确规定外，<span class="bold">如我们对xibeicity服务及第三方服务合作做出调整、中止或终止而对第三方应用服务产生影响的，xibeicity公司不承担相应责任。</span>
        </li>
        <li class="bold">
          7.2 您通过第三方应用或服务使用xibeicity时，xibeicity可能会调用第三方系统或者通过第三方支持您的使用或访问，使用或访问的结果由该第三方提供。
        </li>
        <li>
          <span class="bold">7.3</span>
          您理解并同意，您在使用xibeicity服务中的第三方应用及服务时，除遵守本协议的约定外，还应遵守第三方用户协议；为实现第三方应用及服务，企业组织管理员基于企业组织授权开通第三方服务，向该独立第三方提供企业通讯录信息。<span
            class="bold">您在选择使用第三服务前应充分了解第三方服务的产品功能、服务协议及隐私保护政策，再选择是否开通功能并遵守第三方用户协议。</span>
        </li>
      </ul>
    </section>

    <section>
      <h2>
        八、服务中止或终止
      </h2>
      <ul>
        <li class="bold">
          8.1 鉴于互联网服务的特殊性，xibeicity公司有权对服务内容进行变更，也可能会中断、中止或终止全部或者任一部分的服务，包括免费服务和/或收费服务，并在xibeicity官网上以公示的形式通知您。
        </li>
        <li class="bold">
          8.2 您了解并同意，xibeicity公司可能定期或不定期地对提供网络服务的平台设备、设施和软硬件进行维护或检修，我们将提前通告您，如因此类情况而造成收费服务在合理时间内中止的，xibeicity公司无需承担责任。
        </li>
        <li>
          <span class="bold">8.3 如存在下列违约情形之一，xibeicity公司有权立即对您中止或终止服务，并要求您赔偿损失：</span>
          <ul>
            <li>
              8.3.1 您违反第四条之注册义务；
            </li>
            <li>
              8.3.2 您使用收费服务但未按规定支付相应服务费；
            </li>
            <li>
              8.3.3 您违反第五条服务使用规范之规定。
            </li>
          </ul>
        </li>
      </ul>
    </section>

    <section>
      <h2>
        九、隐私政策
      </h2>
      <ul>
        <li>
          <span class="bold">9.1</span> 您在xibeicity服务注册的账户具有密码保护功能，以确保您基本信息资料的安全，<span class="bold">请您妥善保管账户及密码信息</span>。
        </li>
        <li>
          <span class="bold">9.2</span>
          xibeicity公司努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您存储在xibeicity中的信息和通信内容不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。xibeicity会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，xibeicity将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失，但同时也请<span
            class="bold">您注意在信息网络上不存在绝对完善的安全措施，请妥善保管好相关信息。</span>
        </li>
        <li>
          <span class="bold">9.3</span> 您应当保管好终端、账号及密码，并妥善保管相关信息和内容。<span class="bold">因您自身原因导致的数据丢失或被盗以及在本软件及服务中相关数据的删除或储存失败的责任由您自行承担。</span>
        </li>
        <li class="bold">
          9.4 其他隐私条款见《xibeicity隐私政策》。
        </li>
      </ul>
    </section>

    <section>
      <h2>
        十、知识产权
      </h2>
      <ul>
        <li>
          <span class="bold">10.1</span> 您了解及同意，<span class="bold">除非xibeicity另行声明</span>，本协议项下服务包含的所有产品、技术、软件、程序、源代码、数据、商标、服务标识、商号、其他专有标识、Logo、应用程序编程接口（API）及服务自带的其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、网站架构、版面或界面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利均归xibeicity或其关联公司所有。
        </li>
        <li class="bold">
          10.2
          您应保证，除非经xibeicity书面授权，对于上述权利您不得（并不得允许任何第三人）实施包括但不限于复制、传播、展示、镜像、上传、下载、出租、出借、出售、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译，或以其它方式发现原始码等的行为，也不得向他人表明您有权展示、使用或做其他处理。
        </li>
        <li class="bold">
          10.3 您理解并同意授权xibeicity公司在宣传和推广中使用您的名称、商标、商号、服务标识，但仅限于表明您属于我们的客户或合作伙伴。
        </li>
      </ul>
    </section>

    <section>
      <h2>
        十一、有限责任
      </h2>
      <ul>
        <li class="bold">
          11.1
          除另有约定外，xibeicity均不对由于Internet连接故障，电脑、通讯或其他系统的故障，电力故障，黑客攻击、罢工，暴乱，起义，骚乱，火灾，洪水，风暴，爆炸，战争，政府行为等不可抗力，您自行负责的生产力或生产资料不足，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。
        </li>
        <li>
          <span class="bold">11.2 不论是否可以预见，不论是源于何种形式的行为，xibeicity不对由以下原因造成的任何利润损失（无论直接或间接产生）、商誉损失、机会损失、数据丢失（另有约定除外）或您可能招致的任何间接或附带损失承担责任：</span>
          <ul>
            <li>
              11.2.1 使用或不能使用本服务；
            </li>
            <li>
              11.2.2 通过本服务购买或获取任何非xibeicity产品，样品，数据，信息或进行交易等；
            </li>
            <li>
              11.2.3 非xibeicity过错导致的未经授权的存取或修改数据或数据的传输；
            </li>
            <li>
              11.2.4 第三方所作的陈述或行为。
            </li>
          </ul>
        </li>
        <li class="bold">
          11.3
          您充分了解并同意，鉴于互联网体制及环境的特殊性，您在服务中自主分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，xibeicity对此不承担任何责任。
        </li>
        <li class="bold">
          11.4
          您了解并同意，在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，xibeicity对此不承担任何责任。
        </li>
      </ul>
    </section>

    <section>
      <h2>
        十二、违约责任及赔偿
      </h2>
      <ul>
        <li class="bold">
          12.1
          您同意并接受：如果xibeicity公司发现或收到他人举报您有违反本协议约定的，xibeicity公司有权依照相关法律法规的规定对相关举报内容向所属企业组织用户核实、转通知、删除、屏蔽、限制、暂停所属企业组织用户部分或全部服务（包括但不限于限制增加成员、限制发送消息等）、终止所属企业组织用户使用部分或全部本服务、注销所属企业组织用户账号及向有关机关报告，并追究企业组织用户法律责任等措施。
        </li>
        <li class="bold">
          12.2
          您同意，由于您通过服务上载、传送或分享之信息、使用本服务其他功能、违反本协议、或您侵害他人任何权利因而衍生或导致任何第三人向xibeicity公司及其关联公司提出任何索赔或请求，或xibeicity公司及其关联公司因此而发生任何损失，您同意将足额进行赔偿（包括但不限于合理律师费）。
        </li>
        <li>
          <span class="bold">12.3</span> 您同意，如您通过xibeicity服务向他人发送垃圾短信，经电信运营商或其他监督部门查实的，<span class="bold">“xibeicity”除依照12.1对违规企业组织用户进行处理，将视情况给予违规企业组织用户年服务费1倍至5倍标准的违约罚款，如因此给“xibeicity”造成运维损失的</span>，包括但不限于xibeicity产品的通信平台停用，行政主管部门对xibeicity平台给予行政处罚或其他用户因xibeicity平台停用主张损失赔偿等，<span
            class="bold">由违规企业组织用户承担</span>。
        </li>
      </ul>
    </section>

    <section>
      <h2>
        十三、有效通知
      </h2>
      <ul>
        <li class="bold">
          13.1 xibeicity公司向您发出的任何通知，可采用电子邮件、xibeicity页面公开区域公告、系统内提示、手机短信或常规信件等方式，该等通知自送达之日视为有效通知。
        </li>
        <li>
          <span class="bold">13.2</span> 您同意，您向xibeicity发出的任何通知应发至xibeicity公司对外正式公布或以<span class="bold">第十五条</span>告知用户的联系方式、通信地址、电子邮件等联系信息，或使用其他xibeicity公司认可的其他通知方式进行送达。
        </li>
      </ul>
    </section>

    <section>
      <h2>
        十四、争议解决及其他
      </h2>
      <ul>
        <li class="bold">
          14.1 本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并以北京市海淀区人民法院或有专属管辖权的法院作为第一审法院。
        </li>
        <li>
          <span class="bold">14.2</span> 如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。
        </li>
        <li>
          <span class="bold">14.3</span> xibeicity公司于用户过失或违约时放弃本协议规定的权利的，不得视为其对用户的其他或以后同类之过失或违约行为弃权。
        </li>
        <li class="bold">
          14.4 本协议应取代双方此前就本协议任何事项达成的全部口头和书面协议、安排、谅解和通信。
        </li>
        <li>
          <span class="bold">14.5</span> 您理解并同意，<span class="bold">xibeicity公司有权自主决定经营策略并根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异议。</span>
        </li>
      </ul>
    </section>

    <section>
      <h2>
        十五、联系方式
      </h2>
      <p>
        您可以拨打xibeicity5*9小时（9:00-18:00）客服热线（010567890）或通过以下联系地址与我们联系：
      </p>
      <p>
        xibeicity.com
      </p>
      <p>
        联系部门： 客户服务部
      </p>
      <p>
        电子邮箱：service@xibeicity.com
      </p>
      <p>
        联系地址：****
      </p>
    </section>

  </div>
</template>

<script>
export default {
  name: "agreement"
}
</script>

<style scoped>

* {
  padding: 0;
  margin: 0;
}

body {
  padding: 6%;
  line-height: 1.5;
  font-size: 14px;
}

h1 {
  font-size: 1.5em;
  text-align: center;
  font-weight: 700;
}

summary {
  display: block;
  padding: 1em 0 0.6em 0;
}

h2 {
  padding: 0.8em 0;
  font-size: 1.1em;
}

h3 {
  padding: 0.8em 0;
  font-size: 1em;
}

p {
  padding: 0.5em 0;
}

.time {
  text-align: right;
}

.bold {
  font-weight: 700;
}

.line {
  text-decoration: underline;
}

ul {
  list-style: none;
}

li {
  list-style: none;
  padding: 0.3em 0;
}

a {
  text-decoration: none;
}

ul li ul {
  padding-left: 0.5em;
}
</style>