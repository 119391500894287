<template>
  <div class="website-box" v-if="$route.path!=='/book/reader'">
    <div style="display: flex;flex-direction: column">
      <HeaderTop></HeaderTop>
      <Header></Header>
      <router-view v-if="$route.name!=='Login'  && $route.name!=='register'"></router-view>
      <router-view v-else></router-view>
    </div>
    <Footer v-if="$route.name!=='Login'  && $route.name!=='register'"></Footer>
  </div>
  <div class="website-box" v-else>
    <router-view></router-view>
  </div>
</template>
<script>

import * as types from './store/type'
import {mapState} from 'vuex'
import store from "./store";
import router from "./router";
import Footer from "./components/Footer";
import HeaderTop from "./components/HeaderTop";
import Header from "./components/Header";

export default {
  name: 'App',
  components: {Header, HeaderTop, Footer},
  data() {
    return {
      msg: '',
      NavMenuCollapse: false,
    }
  },

  created() {

    // this.chat();
    if (this.access_token) {
      // this.checkLogin();
    }
  },
  computed: mapState({
    access_token: state => state.access_token,
  }),
  watch: {
    $route() {
    },
    access_token() {
      // this.localSocket();
    }
  },
  methods: {

    //app.vue
    checkLogin() {
      let that = this;
      that.axios.post('/login/check-login', {}).then(res => {
        if (res.code == 100) {
          // 401 清除token信息并跳转到登录页面
          store.commit(types.LOGOUT)
          // 只有在当前路由不是登录页面才跳转
          router.currentRoute.path !== 'login' &&
          router.replace({
            path: '#/login',
            query: {redirect: router.currentRoute.path},
          })
        }
      })
    },
    localSocket() {
      let that = this;
      if ("WebSocket" in window) {
        // console.log("您的浏览器支持 WebSocket!");
        // location.host
        that.ws = new WebSocket("ws://127.0.0.1:8282");
        that.global.setWs(that.ws);
        that.ws.onopen = function () {
        };
        that.ws.onmessage = function (res) {
          var WsData = JSON.parse(res.data);
          switch (WsData.SendType) {
            case 'CONNECT_SUCCESS'://链接成功
              that.axios.post('/common/socket_login', {client: WsData.client})
              break;
            case "notification":
              that.$notify(WsData);
              break;

          }
        };
        that.ws.onclose = function () {
          //断线重新连接
          setTimeout(() => {
            that.localSocket();
          }, 2000);
        };
      } else {
        // 浏览器不支持 WebSocket
        console.log("您的浏览器不支持 WebSocket!");
        this.openNotificationWithIcon('error', '浏览器', '您的浏览器不支持显示消息请更换', 1, 1)
      }
    },
    NavMenuShow() {
      this.NavMenuCollapse = !this.NavMenuCollapse;
    },
  },
}
</script>

<style lang="less">
body {
  padding: 0;
  margin: 0;
}

.website-box {
  width: 100%;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  .website-main {
    width: 1100px;
    margin: 0 auto;
    min-height: calc(100vh - 80px);
  }


  .login-main {
    width: 100%;
    margin: 0 auto;
  }
}

</style>
