/**
 * Created by superman on 21/7/20.
 * vuex types
 */

export const LOGIN = 'login';

export const LOGOUT = 'logout';

export const TITLE = 'title'

export const GET_NAV_MENU = 'getNavMenu'

export const GET_NAV_MENU_MODULES = 'getNavMenuModules'